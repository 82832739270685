<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-form>
        <b-row>
          <!-- Name Building -->
          <b-col cols="12">
            <b-form-group label-for="name-document">
              <template v-slot:label>
                Tiêu đề <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tiêu đề"
                rules="required"
              >
                <b-form-input
                  id="name-document"
                  v-model="documentTitle"
                  name="documentTitle"
                  placeholder="Nội dung tài liệu"
                  :state="getElementState(errors)"
                  aria-describedby="input-1-live-feedback"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="name-document">
              <template v-slot:label>
                Mô tả tài liệu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mô tả tài liệu"
                rules="required"
              >
                <b-form-input
                  id="des-document"
                  v-model="documentDescription"
                  name="documentDescription"
                  placeholder="Mô tả"
                  :state="getElementState(errors)"
                  aria-describedby="input-1-live-feedback"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="File"
              label-for="file"
            >
              <b-form-file
                ref="inputFileRef"
                :placeholder="placeholder"
                @change="uploadFile"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="float-left">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="invalid"
                @click="handleSaveData"
              >
                Lưu
              </b-button>
            </div>

          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { getUser } from '@/auth/utils'

export default {
  name: 'FormTeacherDocument',
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BOverlay,
  },
  props: {
    dataSendForm: {
      type: Object,
      default: () => ({
        id: null,
        rowNum: null,
        name: '',
        mediaId: null,
        mediaName: '',
        url: '',
        vgt_id: 0,
        originalIndex: 0,
      }),
    },
    modalShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cUser: getUser(),
      isLoading: false,
      documentTitle: '',
      documentFile: '',
      documentDescription: '',
      mediaIds: [],
      nameFile: null,
      placeholder: 'Chọn file để tải tài liệu...',
      required,
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      documents: 'documents/documents',
    }),
    isCreated() {
      return !this.dataSendForm.id
    },
  },
  created() {
    // init data FROM API
    if (!this.isCreated) {
      this.documentTitle = this.dataSendForm.title
      this.documentDescription = this.dataSendForm.description
      this.placeholder = this.dataSendForm.name
    }
  },
  methods: {
    ...mapActions({
      getDocuments: 'documents/getDocuments',
      uploadDocument: 'teacherDocument/uploadDocument',
      saveTeacherDocument: 'teacherDocument/saveTeacherDocument',
    }),
    // handle even
    async handleSaveData(e) {
      e.preventDefault()
      if (this.mediaIds) {
        const param = {
          id: this.isCreated ? null : this.dataSendForm.id,
          status: 1,
          name: this.documentTitle,
          mediaId: this.mediaIds[0] ? this.mediaIds[0] : this.dataSendForm.mediaId,
          description: this.documentDescription,
          teacherId: this.cUser.teacherId ? this.cUser.teacherId : null,
          delFlag: 0,
        }
        const res = await this.saveTeacherDocument(param)
        if (res.isSuccessful) {
          this.showToast('Thành công', 'CheckIcon', 'success')
          this.$emit('saveDataSuccess', true)
        } else {
          this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'warning')
        }
      }
    },

    async getInitData() {
      // await this.getDocuments()
    },

    async uploadFile(e) {
      this.isLoading = true
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File tài liệu đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        this.isLoading = false
        return
      }

      files.forEach(file => formData.append('files', file))
      this.mediaIds = await this.uploadDocument(formData)
      if (this.mediaIds.length > 0) {
        this.showToast('Upload file thành công', 'CheckIcon', 'success')
        await this.getInitData()
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
      this.isLoading = false
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped>

</style>
